import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { getLocaleCurrencySymbol } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { CONTACTS } from '../../../../common/contacts.constants';
import { BehaviorSubject, Observable } from 'rxjs';
import { Contact } from '../../../../common/Contact';
import { content_cl } from '../content/content-cl';
import { content_pe } from '../content/content-pe';
import { content_co } from '../content/content-co';
import { content_mx } from '../content/content-me';
import { content_ar } from '../content/content-ar';

@Injectable()
export class LocaleService {
    contactList: Contact[] = CONTACTS;

    locale: string;
    symbol: string;
    currencyName: string;
    currentContact = this.contactList[0];
    currentCountryCode = this.currentContact.country_code;

    contactSource: BehaviorSubject<Contact>;
    contactMessage: Observable<Contact>;

    TLD = 'co'; // window.location.href.split('.')[2].split('/')[0].toLowerCase();

    selectorGTM = {
        GTMcl: 'GTM-5RT39ZC',
        // GTMco: '',
        // GTMpe: ''
    };

    selectorGtag = {
        clGtag: 'UA-86474748-1',
        //coGtag: '',
        //peGtag: ''
    };

    constructor(
        private http: HttpClient,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
        this.locale = this.getLocale(this.TLD);
        this.symbol = getLocaleCurrencySymbol(this.locale);
        // this.currencyName = getLocaleCurrencyName(this.locale);
        this.currentCountryCode = (this.TLD === 'mx' ? 'me' : this.TLD);
        this.currentContact = this.getContact();
        this.contactSource = new BehaviorSubject<Contact>(this.currentContact);
        this.contactMessage = this.contactSource.asObservable();

        const contactChile = this.contactList.find(x => x.country === 'Chile');
        contactChile.main_phone = '+56 2 3210 9063';
        contactChile.main_phone_href = '+56232109063';
        const contactPeru = this.contactList.find(x => x.country === 'Perú');
        contactPeru.main_phone = '+51 1 1707 0316';
        contactPeru.main_phone_href = '+5117070316';
    }

    updateContact(contact): void {
        this.contactSource.next(contact);
    }

    getMessage(): Observable<Contact> {
        return this.contactMessage;
    }

    getContact(): Contact {
        return this.contactList.filter(c => c.country_code === this.currentCountryCode)[0];
    }

    getLocale(tld: string): string {
        const TLD = tld.toLowerCase();
        if (TLD === 'net') {
            return 'en-US';
        } else if (TLD === 'cl' || TLD === 'ar' || TLD === 'mx' ||
            TLD === 'ec' || TLD === 'co' || TLD === 'pe') {
            return 'es-' + (TLD.toUpperCase());
        } else {
            return 'es-CL';
        }
    }

    getContent() {
        let content = content_cl;
        switch (this.locale) {
            case 'es-PE':
                content = content_pe;
                break;
            case 'es-CO':
                content = content_co;
                break;
            case 'es-MX':
                content = content_mx;
                break;
            case 'es-AR':
                content = content_ar;
                break;
            default:
                content = content_cl;
        }
        return content;
    }

    getGTM(): string {
        let element: string;
        switch (this.locale) {
            // case 'es-CO':
            //     element = this.selectorGTM.GTMco;
            //     break;
            // case 'es-PE':
            //     element = this.selectorGTM.GTMpe;
            //     break;
            // case 'es-MX':
            //     break;
            // case 'es-AR':
            //     break;
            case 'es-CL':
                element = this.selectorGTM.GTMcl;
                break;
            default:
                element = '';
        }
        return element;
    }
    //Selection Google Analytics ID
    getGtag(): string {
        let element: string;
        switch (this.locale) {
            // case 'es-CO':
            //     element = this.selectorGtag.coGtag;
            //     break;
            // case 'es-PE':
            //     element = this.selectorGtag.peGtag;
            //     break;
            // case 'es-MX':
            //     break;
            // case 'es-AR':
            //     break;
            case 'es-CL':
                element = this.selectorGtag.clGtag;
                break;
            default:
                element = '';
        }
        return element;
    }
}
